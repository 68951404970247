.App {
  color: white;
  text-align: center;
}

html {
  width: 357px;
  height: 600px;
}

.root {
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
  font-display: "swap";
  font-weight: 500;
}

body {
  font-size: 1.167rem;
}

body,
body * {
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
  font-style: normal;

  font-weight: normal;
  color: var(--color-text-default);
}
* {
  /* Keyword values */
  overscroll-behavior: auto; /* default */
  overscroll-behavior: contain;
  overscroll-behavior: none;

  /* Two values */
  overscroll-behavior: auto contain;

  /* Global values */
  overscroll-behavior: inherit;
  overscroll-behavior: initial;
  overscroll-behavior: revert;
  overscroll-behavior: revert-layer;
  overscroll-behavior: unset;

  overscroll-behavior-y: contain;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background-color: white;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

/* It is to remove the arrows in the number input field  */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*React slick*/

/* .slick-prev {
  left: 10px !important;
  position: fixed !important;
  width: 35px !important;
  height: 35px !important;
  z-index: 9999;
}
.slick-next {
  right: 20px !important;
  position: fixed !important;
  width: 35px !important;
  height: 35px !important;
  z-index: 9999;
  display: none;
} */

/* .slick-next:before,
.slick-prev:before {
  font-size: 35px !important;
  /* opacity: 1 !important; */
/* color: #9aa9cb !important; */
/* } */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.invalid__shake {
  border: 2px solid #b91c1c;
  animation: shake 0.25s 2;
}

@keyframes shake {
  25% {
    translate: 6px 0;
  }
  50% {
    translate: -6px 0;
  }
  75% {
    translate: 6px 0;
  }
}

@keyframes slide-to-0 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes slide-to-1 {
  0% {
    transform: translateX(-200%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-to-2 {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-to-3 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.click-me-btn {
  -webkit-box-shadow: 0px 0px 3px 1px rgba(253, 224, 71, 1);
  -moz-box-shadow: 0px 0px 3px 1px rgba(253, 224, 71, 1);
  box-shadow: 0px 0px 3px 1px rgba(253, 224, 71, 1);
}

.img-grey-scale {
  filter: grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.img-grey-scale:not(.transitioning) {
  filter: grayscale(95%);
}

.img-grey-scale.transitioning {
  filter: grayscale(0%);
}

.shine-animation {
  background-repeat: no-repeat;
  background-position: -100% 0;

  background-image: linear-gradient(
    45deg,
    transparent 20%,
    transparent 40%,
    rgba(255, 255, 255, 0.3) 60%,
    rgba(255, 255, 255, 0.3) 55%,
    transparent 70%,
    transparent 100%
  );

  background-size: 200% 200%;

  animation: shine 1s ease-in-out infinite;
}

@keyframes shine {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
