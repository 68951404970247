@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.root {
  background: linear-gradient(
    to bottom,
    #000000,
    #010007,
    #020108,
    #3016b2,
    #4722cc,
    #a954fd
  );
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f1f20;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.transparent-bg {
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the opacity value as needed */
}

/* backlight component */
.orange-bg-light {
  background: rgb(241, 144, 91);
  background: radial-gradient(
    circle,
    rgba(241, 144, 91, 0.40242034313725494) 0%,
    rgba(255, 255, 255, 0) 94%
  );
}

.blue-bg-light {
  background: rgb(127, 130, 222);
  background: radial-gradient(
    circle,
    rgba(127, 130, 222, 1) 0%,
    rgba(255, 255, 255, 0) 94%
  );
}

.yellow-bg-light {
  background: rgb(246, 206, 124);
  background: radial-gradient(
    circle,
    rgba(246, 206, 124, 1) 0%,
    rgba(255, 255, 255, 0) 94%
  );
}

.pink-bg-light {
  background: rgb(219, 119, 162);
  background: radial-gradient(
    circle,
    rgba(219, 119, 162, 1) 0%,
    rgba(255, 255, 255, 0) 94%
  );
}

.dark-orange-light {
  background: rgb(235, 98, 75);
  background: radial-gradient(
    circle,
    rgba(235, 98, 75, 1) 0%,
    rgba(255, 255, 255, 0) 94%
  );
}

.dropdown-scrollbar::-webkit-scrollbar-track {
  /* border: 1px solid white !important; */
  background-color: transparent !important;
  border-radius: 10px !important;
}
.dropdown-scrollbar::-webkit-scrollbar {
  width: 6px !important;
  background-color: transparent !important;
  border-radius: 6px !important;
}
.dropdown-scrollbar::-webkit-scrollbar-thumb {
  background-color: #fff !important;
  border-radius: 6px !important;
}
